<template>
    <h2 class="helvetica title1" v-if="conferenceSubtitle">{{ conferenceSubtitle }}</h2>
</template>

<script lang="ts" setup>
const props = defineProps<{ page: any }>()
const conferenceSubtitle = computed(() => {
    if (props && props.page.date_start && props.page.date_end) {
        const fmt = new Intl.DateTimeFormat("en", { month: "long", day: "numeric" });
        return fmt.formatRange(new Date(props.page.date_start), new Date(props.page.date_end));
    } else return null
})
</script>